// Todo
// topics: politcal, technology, crime, sports, etc
// essentially just a search query, but made a preset

// dependencies
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button } from 'primereact/button'
import { ButtonGroup, Col, Container, Row } from 'reactstrap';

// components

// lib
import { correctKeywords } from '../lib/keywordSpellchecker';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchNews } from '../redux/slices/newsSlice';
import { setNewsMode, setNewsSettings } from '../redux/slices/newsSlice';

// Input options
import countryOptions from '../assets/countryOptions';

function openSearch() {
    // Expand the input width
    const searchInput = document.getElementById("searchInput");
    searchInput.style.width = "300px"; // Change to desired expanded width
    searchInput.style.marginRight = "2rem"; // Change to desired expanded width
    document.getElementById("myTopbar").style.height = "250px";
    const overlay = document.getElementById("overlay");
    overlay.style.display = "block"; // Show the overlay
}

function closeSearch() {
    // Collapse the input width
    const searchInput = document.getElementById("searchInput");
    searchInput.style.width = "150px"; // Change to desired default width
    searchInput.style.marginRight = "0"; // Change to desired expanded width
    document.getElementById("myTopbar").style.height = "0";
    const overlay = document.getElementById("overlay");
    overlay.style.display = "none"; // Hide the overlay
}

function NewsSearch() {
    const dispatch = useDispatch(); //redux
    const { newsMode } = useSelector((state) => state.news); //redux
    const { newsSettings } = useSelector((state) => state.news); //redux

    const changeMode = (mode) => {
        dispatch(setNewsMode(mode)); // Set the news mode in Redux

        // Define the default settings based on the mode
        const defaultSettings = {
            recent: {
                newsSortType: 'Date',
                newsSortBy: 'Desc',
                newsTime: '1d',
                newsQuantity: 50,
                newsCountry: '',
                newsDomain: '',
            },
            casual: {
                newsSortType: 'Tone',
                newsSortBy: 'Desc',
                newsTime: '6h',
                newsQuantity: 50,
                newsCountry: '',
                newsDomain: '',
            },
            intense: {
                newsSortType: 'Tone',
                newsSortBy: 'Asc',
                newsTime: '6h',
                newsQuantity: 50,
                newsCountry: '',
                newsDomain: '',
            },
        };

        const settings = defaultSettings[mode]; // Get the corresponding default settings for the selected mode
        closeSearch();
        dispatch(setNewsSettings(settings)); // Dispatch the settings to Redux
    };

    // Handle setting changes dynamically
    const handleNewsSettingsChange = (key, value) => {
        dispatch(setNewsSettings({ [key]: value })); // Dispatch the updated setting (single key-value pair)
        dispatch(setNewsMode(""));
    };


    // Search params
    const [keyword, setKeyword] = useState('');
    const options = ['Search', 'Cluster Map'];
    const [action, setAction] = useState(options[0]);

    const handleSearch = async () => {
        closeSearch();
        const correctedText = await correctKeywords(keyword);
        if (action === 'Cluster Map') {
            // Assuming the base URL is this FOR CLUSTER MAP
            const fullUrl = `https://api.gdeltproject.org/api/v2/geo/geo?query="${correctedText}"&mode=PointData&format=ImageHTML&timespan=${newsSettings.newsTime}d`;
            // Check if the URL is valid and then open it in a new tab
            if (fullUrl) {
                window.open(fullUrl, '_blank', 'noopener,noreferrer');
            } else {
                // If the URL is not valid, show an alert
                alert("Try changing you search info");
            }
        } else if (action === 'Search') {
            dispatch(fetchNews({ correctedText, newsSettings }));
            setKeyword('')
        }
    };

    // Reference to the input field
    const inputRef = useRef(null);
    const handleKeyPress = (event) => {
        dispatch(setNewsMode(""));
        if (event.key === 'Enter') {
            // Close the keyboard by blurring the input field
            inputRef.current.blur();
            handleSearch();
        }
    };

    // Create a loading component
    // Render loading state, error state, or data
    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    return (
        // NEWS SEARCH COMPONENT
        <div className='newsSearch d-flex pe-sm-5' >
            <input ref={inputRef} onClick={openSearch} onKeyDown={handleKeyPress} id='searchInput' autoComplete="off" className='p-1 ps-3 mx-sm-auto round no-box-shadow' placeholder='Enter keyword' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
            <Container id='myTopbar' className='rounded'>
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <button className="closebtn fs-2 p-0 bg-transparent" onClick={() => closeSearch()}>×</button>
                    <Button onClick={() => handleSearch()} className='newsSearch__button p-1 px-2 mt-2 fs-6 rounded border-0 text-white'>Go</Button>
                </div>
                <Row className='mt-3'>
                    {/* <p>Default Mode</p> */}
                    <Col>
                        <ButtonGroup className='justify-content-center w-100'>
                            <Button
                                onClick={() => changeMode('recent')}
                                active='recent'
                                className={`newsSearch__button rounded-start-custom border-end-0 p-1 px-2 fs-6 ${newsMode === 'recent' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Recent
                            </Button>
                            <Button
                                onClick={() => changeMode('casual')}
                                active='casual'
                                className={`newsSearch__button border-start-0 border-end-0 p-1 px-2 fs-6 ${newsMode === 'casual' ? "bg-success text-white" : "bg-transparent"}`}
                            >
                                Casual
                            </Button>
                            <Button
                                onClick={() => changeMode('intense')}
                                active='intense'
                                className={`newsSearch__button rounded-end-custom border-start-0 p-1 px-2 fs-6 ${newsMode === 'intense' ? "bg-danger text-white" : "bg-transparent"}`}
                            >
                                Intense
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <hr className='m-2'></hr>
                {/* Date, Tone */}
                <Row className='mb-2'>
                    {/* <p>Default Mode</p> */}
                    <Col xs={5} sm={6}>
                        <ButtonGroup className='w-100'>
                            <Button
                                onClick={() => handleNewsSettingsChange('newsSortType', 'Date')}
                                active='Date'
                                className={`newsSearch__button rounded-start-custom border-end-0 p-1 px-2 fs-6 ${newsSettings.newsSortType === 'Date' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Date
                            </Button>
                            <Button
                                onClick={() => handleNewsSettingsChange('newsSortType', 'Tone')}
                                active='Tone'
                                className={`newsSearch__button border-start-0 border-end-0 p-1 px-2 fs-6 ${newsMode === 'intense' ? "bg-danger text-white" : newsMode === 'casual' ? "bg-success text-white" : newsSettings.newsSortType === 'Tone' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Tone
                            </Button>
                            <Button
                                onClick={() => handleNewsSettingsChange('newsSortType', '')}
                                active=''
                                className={`newsSearch__button rounded-end-custom border-start-0 p-1 px-2 fs-6 ${newsSettings.newsSortType === '' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Any
                            </Button>
                        </ButtonGroup>
                    </Col>
                    {/* 
                    these modes set filters automatically
                    casual mode: DateAsc, 1d, 50, none, none
                    intense mode: ToneAsc, 1d?, 50, none, none 
                    */}
                    {/* Sort By: Asc, Desc, none  (Increasing / Decreasing)*/}
                    <Col>
                        <ButtonGroup className='justify-content-end w-100'>
                            <Button
                                onClick={() => handleNewsSettingsChange('newsSortBy', 'Asc')}
                                active='increasing'
                                className={`newsSearch__button rounded-start-custom border-end-0 p-1 px-2 fs-6 ${newsMode === 'intense' ? "bg-danger text-white" : newsSettings.newsSortBy === 'Asc' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Ascend
                            </Button>
                            <Button
                                onClick={() => handleNewsSettingsChange('newsSortBy', 'Desc')}
                                active='Desc'
                                className={`newsSearch__button border-start-0 border-end-0 p-1 px-2 fs-6 ${ newsMode === 'casual' ? "bg-success text-white" : newsSettings.newsSortBy === 'Desc' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Descend
                            </Button>
                            <Button
                                onClick={() => handleNewsSettingsChange('newsSortBy', '')}
                                active=''
                                className={`newsSearch__button rounded-end-custom border-start-0 p-1 px-2 fs-6 ${newsSettings.newsSortBy === '' ? "bg-primary text-white" : "bg-transparent"}`}
                            >
                                Any
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {/* // Todo
                Date Range: 3 months total, calendar from to date, needs date in unix millisecond
                &startdatetime=20170322000000&enddatetime=20170401235959, and remove Time option
                 */}

                <Row>
                    {/* Time: 1h, 6h, 12h, 1-7d (spelled out for user) */}
                    {/* <p>Default Mode</p> */}
                    <Col>
                        <ButtonGroup className='justify-content-between w-100'>
                            <select
                                name="time" id="time"
                                value={newsSettings.newsTime}
                                onChange={(e) => handleNewsSettingsChange('newsTime', e.target.value)}
                                className='newsSearch__button p-sm-1 rounded bg-transparent'
                            >
                                <option value="1h">1 Hour</option>
                                <option value="6h">6 Hour</option>
                                <option value="12h">12 Hour</option>
                                <option value="1d">1 Day</option>
                                <option value="2d">2 Day</option>
                                <option value="3d">3 Day</option>
                                <option value="4d">4 Day</option>
                                <option value="5d">5 Day</option>
                                <option value="6d">6 Day</option>
                                <option value="7d">7 Day</option>
                                <option value="1m">1 Month</option>
                                <option value="3m">3 Months</option>
                                <option value="6m">6 Months</option>
                                <option value="1y">1 Year</option>
                            </select>

                            {/* NumOfRecords: 50, 100, 150, 200, 250 (Quantity: only when no results, 150-250)*/}
                            <select
                                name="quantity" id="quantity"
                                value={newsSettings.newsQuantity}
                                onChange={(e) => handleNewsSettingsChange('newsQuantity', e.target.value)}
                                className='newsSearch__button p-sm-1 rounded bg-transparent'
                            >
                                <option value="50">50 Articles</option>
                                <option value="100">100 Articles</option>
                                <option value="150">150 Articles</option>
                                <option value="200">200 Articles</option>
                                <option value="250">250 Articles</option>
                            </select>

                            {/* Country: none or list of countries, lib for country code mapping, scrolling buttons from left to right custom component */}
                            <select
                                name="country" id="country"
                                value={newsSettings.newsCountry}
                                onChange={(e) => handleNewsSettingsChange('newsCountry', e.target.value)}
                                className='newsSearch__button p-sm-1 rounded bg-transparent'
                            >
                                {countryOptions.map((country, index) => (
                                    <option
                                        key={index}
                                        value={country.value}
                                    >
                                        {country.label}
                                    </option>
                                ))}
                            </select>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        {/* domains: none or input chips, or buttons? separated by commas for api call*/}
                        <input
                            name="domain" id="domain"
                            value={newsSettings.newsDomain}
                            onChange={(e) => handleNewsSettingsChange('newsDomain', e.target.value)}
                            onKeyDown={handleKeyPress}
                            className='newsSearch__button p-1 rounded w-100 bg-transparent'
                            placeholder='(Optional) domain.com, domain3.uk, ... up to 5'
                        >
                        </input>
                    </Col>
                </Row>

                {/* option for search of cluster map, after search, button appears, Cluster map users search results */}
                {/* Pagination to preserve load speed, load on scroll close by */}
                {/* close keyboard keydown in phone */}

            </Container>

            {/* Transparent overlay */}
            <div id="overlay" className="overlay" onClick={closeSearch}></div>
        </div >
    )
}

export default NewsSearch