import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { firestore } from '../../firebase';  // Import Firestore instance
import { doc, setDoc, serverTimestamp, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

// Thunk action to create user and user profile in Firestore
export const createUser = createAsyncThunk(
    'user/createUser',
    async (authData, { rejectWithValue }) => {
        try {
            const { uid, email, photoURL, displayName } = authData.user;

            // Step 1: Create the user document in the 'users' collection
            const userDocRef = doc(firestore, 'users', uid);  // 'uid' is used as the document ID
            const userDoc = await getDoc(userDocRef);


            // If user already exists, stop the signup process and return a message to login
            if (userDoc.exists()) {
                console.log("User already exists, stopping signup process.");
                return rejectWithValue('User already exists. Please log in instead.');
            }

            // Step 2: Generate a unique @name
            const generatedName = await generateUniqueName(displayName);

            const userData = {
                user_uid: uid,
                email: email,
                profile_photo_url: photoURL,
                signup_date: serverTimestamp(),  // Firebase timestamp for signup date
                '@name': generatedName,  // Autogenerated unique @name
                display_name: displayName || 'Anonymous',
            };

            // Save user document in Firestore
            await setDoc(userDocRef, userData);

            // Step 3: Create the user profile document in 'user_profiles' collection in the background
            const userProfileDocRef = doc(firestore, 'user_profiles', uid);
            const userProfileData = {
                user_uid: uid,
                networth: 0,  // Set default networth to 0
                member_type: 'FREE',  // Default membership type
                permissions: {
                    intense: false,  // Default permission for intense content
                },
                preferences: {
                    language: 'English',  // Default language from browser
                    notifications: true,  // Default notifications setting
                    geowatch: true,  // Enable geowatch for artifact collection
                    privacySettings: {
                        visibility: 'public',  // Default to public visibility
                        searchable: true,  // Allow searchability by default
                    },
                },
                account_status: 'active',  // Default account status
                bio: 'Just a regular person!',
                birthdate: '',  // Will be updated later
                location: '',  // Can be pulled from GPS/browser settings later
                gender: '',  // Optional field for later
                contacts: [],  // Empty contacts by default
            };

            // Save user profile document in Firestore
            await setDoc(userProfileDocRef, userProfileData);
            return { user_uid: uid, userData, userProfileData };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Helper function to generate a unique @name
const generateUniqueName = async (displayName) => {
    const baseName = displayName.toLowerCase().replace(/\s+/g, '');  // Strip spaces and lowercase
    let uniqueName = baseName;
    let suffix = 0;

    // Check if the baseName exists in Firestore and keep incrementing suffix until a unique name is found
    while (!(await checkIfNameIsAvailable(uniqueName))) {
        suffix += 1;
        uniqueName = `${baseName}${suffix}`;  // Append numbers to make name unique
    }

    return uniqueName;
};

// Helper function to check if @name is available
const checkIfNameIsAvailable = async (userName) => {
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('@name', '==', userName));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;  // Returns true if name is available
};


// Thunk action to sign in a user and store their data in Redux
// if user doc does not exist
export const signinUser = createAsyncThunk(
    'user/signinUser',
    async (uid, { rejectWithValue }) => {
        try {
            // Fetch the user document from Firestore using the UID
            const userDocRef = doc(firestore, 'users', uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                // If the user document exists, return the data
                return userDoc.data();  // This will be passed to the fulfilled reducer
            } else {
                // No user exists with this UID or email, allow sign up or throw an error
                throw new Error('No account found. Signing out.');
            }
        } catch (error) {
            return rejectWithValue(error.message);  // This will be passed to the rejected reducer
        }
    }
);


// change the state based on the called function
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        userData: null,
        userProfileData: null,
        userContacts: null,
        loading: null,
        error: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload.userData;
                state.userProfileData = action.payload.userProfileData;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(signinUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(signinUser.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;  // Store the user document in state
            })
            .addCase(signinUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;  // Store the error message
            });
    }
})

//action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;