import React from 'react'

import CountdownTimer from '../components/CountdownTimer';

function HomePage() {
  return (
    <div className="App">
      <div className='text-end'>
        <h1 className='jumbo-text pe-2'>bluith</h1>
        <p className='slogan-text pe-2 pe-sm-3'> -- play the world, live the game</p>
        {/* <p className='mb-5 text-end text-black slogan-text'> -- enter at your own risk</p>  */}
        {/* <button className='btn-primary rounded'>Enter</button> */}
        {/* <div className="lds-spinner mt-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
        <CountdownTimer />
      </div>
    </div>
  )
}

export default HomePage