import React from 'react'
import { Link } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';
import { auth, signOut } from '../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/slices/userSlice';

function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    const overlay2 = document.getElementById("overlay2");
    overlay2.style.display = "none"; // Show the overlay
}

function SidebarNav() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const handleSignOut = () => {
        signOut(auth)
          .then(() => {
            dispatch(setUser(null))
            console.log('User signed out successfully');
          })
          .catch((error) => {
            console.error('Error during sign-out:', error);
          });
      };

    return (
        <>
            <div id="mySidebar" className="sidebar shadowed">
                <button className="closebtn p-1 bg-transparent" onClick={() => closeNav()}>×</button>
                <div onClick={() => closeNav()} className='ms-2'>
                    <Link to="/home">Home</Link>
                    <Link to="/news">News</Link>
                    <Link to="/weather">Weather</Link>
                    <Link to="/sports">Sports</Link>
                    <Link to="/market">Stocks</Link>
                    <Link to="/social/">Social</Link>
                    <Link to="/dating">Dating</Link>
                    <Link to="/jobs">Jobs</Link>
                    <Link to="/market">Market</Link>
                    <Link to="/military">Military</Link>
                    <Link to="/government">Government</Link>
                    <Link to="/education">Education</Link>
                    <Link to="/research">Research</Link>
                    <Link to="/health">Health</Link>
                    <Link to="/safety">Safety</Link>
                    <Link to="/insurance">Insurance</Link>
                    <Link to="/entertainment">Entertainment</Link>
                    <Link to="/casino">Games</Link>
                    <Link to="/bank">Bank</Link>
                    <Link to="/ledger">Ledger</Link>
                    <Link to="/data">Data</Link>
                    <Link to="/artifacts">Artifacts</Link>
                    <Link to="/messages">Messages</Link>
                    <Link to="/contacts">Contacts</Link>
                    <Link to="/profile/">Profile</Link>
                    <Link to="/manual/">Manual</Link>
                    {user ? <a href="/" onClick={handleSignOut}>Sign out</a> : <a href="/">Sign in</a>}
                </div>
                    <ThemeSwitcher />
            </div>

            {/* Transparent overlay */}
            <div id="overlay2" className="overlay2" onClick={closeNav}></div>
        </>
    )
}

export default SidebarNav