import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import SignInPage from "./pages/SignInPage";
import News from "./pages/news/News";
import ErrorPage from "./pages/ErrorPage";

import { PrimeReactProvider } from 'primereact/api';
import Header from "./components/Header";
import NewsArticle from "./pages/news/NewsArticle";

import { auth } from "./firebase";
import { useEffect } from "react";
import { setUser } from './redux/slices/userSlice';
import { useDispatch, useSelector } from "react-redux";

// lib
import { extractSerializableUserData } from './lib/userUtils'
import HomePage from "./pages/HomePage";
import SocialPage from "./pages/social/SocialPage";
import GoogleTranslate from "./components/GoogleTranslate";

// Global error handler
window.onerror = function(message, source, lineno, colno, error) {
  const log = `Error: ${message} at ${source}:${lineno}:${colno}`;
  alert(log);  // Alerts the error on the screen (for temporary debugging)
  
  // Optionally, send the error to your server for later viewing
  // fetch('https://your-server.com/log-error', {
  //   method: 'POST',
  //   body: JSON.stringify({ message, source, lineno, colno, error })
  // });
};

function App() {
  // const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Only set the user in state if we are not in the middle of sign-up or login flow
        dispatch(setUser(extractSerializableUserData(user)));
      }
    });
    return unsubscribe;
  }, []);

  return (
    <PrimeReactProvider>
      <BrowserRouter>
        <Header />
        {/* google translate popup window for feedback wont go away */}
        {/* <GoogleTranslate /> */}
        <Routes>
          {/* 
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={300} // duration of the transition
            classNames="fade" // CSS class to apply the transition
          > */}

          <Route path="/" element={<SignInPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/news/" element={<News />} />
          <Route path="/social/" element={<SocialPage />} />
          <Route path="/news/news-article/" element={<NewsArticle />} />
          {/* <Route path="/search-results/:searchParams" element={<SearchResults />} />
      <Route path="/profile/:id" element={<Profile />} />
      <Route path="/my-account/" element={<MyAccount />} />
      <Route path="/messages/" element={<Messages />} />
      <Route path="/chat/" element={<Chat />} />
      <Route path="/contacts/" element={<Contacts />} /> */}
          <Route path="*" element={<ErrorPage />} />

          {/* </CSSTransition>
        </TransitionGroup> */}
        </Routes>
      </BrowserRouter>
    </PrimeReactProvider>
  );
}

export default App;
