import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const targetDate = 1736139600000
    const now = new Date().getTime();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0'),
        milliseconds: Math.floor(difference % 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1); // Update every millisecond

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='CountdownTimer__Container me-2 mt-3 '>
      <div className='CountdownTimer__timerText'>
        <span>{timeLeft.days || '0'}d </span>
        <span>{timeLeft.hours || '0'}h </span>
        <span>{timeLeft.minutes || '0'}m </span>
        <span>{timeLeft.seconds || '0'}s </span>
        {/* <span>{timeLeft.milliseconds || '0'}ms</span> */}
      </div>
    </div>
  );
};

export default CountdownTimer;
