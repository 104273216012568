import React, { useEffect, useState } from 'react';
import logo from '../assets/placeholder-image.png'
import PostCard from './PostCard';
import { addPost } from '../redux/slices/socialSlice';
import { useDispatch, useSelector } from 'react-redux';

function PostCreator({ onPostSubmit }) {
    const [text, setText] = useState('');
    const [media, setMedia] = useState([]);
    const [mediaPreview, setMediaPreview] = useState(null);  // Store the preview URL
    const [hashtags, setHashtags] = useState('');
    const [mentions, setMentions] = useState('');
    const [newPost, setNewPost] = useState(null);
    const dispatch = useDispatch();
    const { user, userData } = useSelector((state) => state.user);
    const langCountry = navigator.language;

    // Update the newPost state when any of the form fields or userData changes
    useEffect(() => {
        if (userData) {
            setNewPost({
                user: {
                    user_uid: userData.user_uid,  // User UID from Redux
                    '@name': userData['@name'],
                    display_name: userData.display_name,
                    user_photo_url: userData.profile_photo_url,
                },
                content: {
                    text,
                    media: mediaPreview,
                    hashtags: hashtags.split(',').map((tag) => tag.trim()),
                    mentions: mentions.split(',').map((mention) => mention.trim()),
                    location: langCountry.split('-')[1],
                    language: langCountry.split('-')[0],
                },
                created: { seconds: Date.now() / 1000 },
            });
        }
    }, [text, media, hashtags, mentions, userData, langCountry]);

    const handleMediaUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                if (file.type === 'image/gif') {
                    // For GIFs, do not resize, but check file size
                    const MAX_GIF_SIZE = 1 * 1024 * 1024; // 5MB limit (adjust as needed)
                    if (file.size > MAX_GIF_SIZE) {
                      alert('GIF is too large. Please upload a GIF smaller than 1MB.');
                    } else {
                      setMedia(file);
                      setMediaPreview(URL.createObjectURL(file));
                    }
                } else {
                    // Process other image types
                    resizeAndConvertImage(file)
                        .then((processedFile) => {
                            setMedia(processedFile);
                            setMediaPreview(URL.createObjectURL(processedFile));
                        })
                        .catch((err) => {
                            console.error('Error processing image:', err);
                            alert('Failed to process the image.');
                        });
                }
            } else {
                alert('Please upload a valid image file.');
            }
        }
    };

    // Function to resize and convert non-GIF images to WEBP
    const resizeAndConvertImage = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (event) => {
                img.onload = async () => {
                    // Set the desired dimensions
                    const MAX_WIDTH = 1200;
                    const MAX_HEIGHT = 675;
                    let width = img.width;
                    let height = img.height;

                    // Calculate the scaling factor to maintain aspect ratio
                    const widthRatio = MAX_WIDTH / width;
                    const heightRatio = MAX_HEIGHT / height;
                    const ratio = Math.min(widthRatio, heightRatio);

                    width = width * ratio;
                    height = height * ratio;

                    // Create a canvas to draw the resized image
                    const canvas = document.createElement('canvas');
                    canvas.width = MAX_WIDTH;
                    canvas.height = MAX_HEIGHT;
                    const ctx = canvas.getContext('2d');

                    // Fill the canvas with white background (optional)
                    ctx.fillStyle = '#FFFFFF';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    // Draw the resized image in the center
                    ctx.drawImage(
                        img,
                        (canvas.width - width) / 2,
                        (canvas.height - height) / 2,
                        width,
                        height
                    );

                    // Function to get the blob with the desired quality
                    const getBlob = (quality) => {
                        return new Promise((resolve) => {
                            canvas.toBlob(
                                (blob) => {
                                    resolve(blob);
                                },
                                'image/webp',
                                quality
                            );
                        });
                    };

                    // Start with quality 0.9 and adjust to fit size constraints
                    let quality = 0.9;
                    let blob = await getBlob(quality);

                    // Reduce quality until the file size is within 200KB
                    while (blob.size > 200 * 1024 && quality > 0.1) {
                        quality -= 0.1;
                        blob = await getBlob(quality);
                    }

                    // Ensure the image is at least 100KB
                    while (blob.size < 100 * 1024 && quality < 1.0) {
                        quality += 0.1;
                        blob = await getBlob(quality);
                    }

                    // Convert blob back to File object
                    const newFile = new File([blob], `${file.name.split('.')[0]}.webp`, {
                        type: 'image/webp',
                        lastModified: Date.now(),
                    });

                    resolve(newFile);
                };

                img.onerror = () => {
                    reject(new Error('Failed to load image'));
                };

                img.src = event.target.result;
            };

            reader.onerror = () => {
                reject(new Error('Failed to read file'));
            };

            reader.readAsDataURL(file);
        });
    };

    // console.log(postData)
    const handleSubmit = (e) => {
        e.preventDefault();


        // Ensure user data exists before submitting
        // if (!userData) {
        //     console.error("User data is not available.");
        //     return;
        // }

        const postData = {
            ...newPost,
            content: {
                ...newPost.content,
                media: media,  // Use the actual Firebase storage URL after upload
            }
        };

        // Dispatch the post to Redux and Firebase
        dispatch(addPost(postData));

        // Clear form
        setText('');
        setMedia([]);
        setMediaPreview(null);
        setHashtags('');
        setMentions('');
    };

    return (
        <div className='post-creator'>
            <form onSubmit={handleSubmit}>
                <textarea
                    placeholder={`What's on your mind?\n(add #hashtags, add @mentions)`}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <div className="post-creator-actions">
                    <label className="image-upload fs-3 px-2 bg-secondary">
                        <input
                            type="file"
                            onChange={handleMediaUpload}
                            accept="image/*"
                        />🌄
                    </label>
                    <button className='' type="submit">⬅</button>
                </div>
                {/* Preview Post if there is content */}
                {(text || mediaPreview) && (
                    <div className='mt-3'>
                        <PostCard post={newPost} />
                    </div>
                )}
            </form>
        </div>
    );
}

export default PostCreator;