import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore, storage } from '../../firebase';  // Import Firestore instance
import { collection, addDoc, getDocs, serverTimestamp, query, orderBy, limit } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import Sentiment from 'sentiment';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

// chatgpt key: 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
// google translate API key: AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk

// Google Translate API URL
const GOOGLE_TRANSLATE_URL = 'https://translation.googleapis.com/language/translate/v2';
// Google Vision Safe Search API URL
const VISION_API_URL = 'https://vision.googleapis.com/v1/images:annotate';
// ChatGPT Moderation API URL
const CHATGPT_MODERATION_URL = 'https://api.openai.com/v1/moderations';

// Thunk to add a post with translation, moderation, sentiment analysis, and language detection
export const addPost = createAsyncThunk(
    'social/addPost',
    async (postData, { rejectWithValue }) => {
        try {
            // Step 1: Translate post text to English and detect language using Google Translate API
            let translatedText = postData.content.text;
            let detectedLanguage = 'en'; // Default to English

            // Step 1: Detect language using a substring of the text
            if (postData.content.text && postData.content.text.trim() !== '') {
                // Extract a substring (first 20 characters) for language detection
                const textForDetection = postData.content.text.substring(0, 10);
                console.log(textForDetection)

                // Language detection API call
                const detectionResponse = await axios.post(
                    GOOGLE_TRANSLATE_URL,
                    {
                        q: textForDetection,
                        target: 'en',
                        format: 'text',
                    },
                    {
                        params: {
                            key: 'AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk', // Use environment variable or secure storage
                        },
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                console.log('Language detection response:', detectionResponse.data);

                detectedLanguage = detectionResponse.data.data.translations[0].detectedSourceLanguage;

                // If the detected language is not English, proceed to translate the entire text
                if (detectedLanguage !== 'en') {
                    const translationResponse = await axios.post(
                        GOOGLE_TRANSLATE_URL,
                        {
                            q: postData.content.text,
                            target: 'en',
                            format: 'text',
                        },
                        {
                            params: {
                                key: 'AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk', // Use environment variable or secure storage
                            },
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );

                    console.log('Translation response:', translationResponse.data);

                    translatedText = translationResponse.data.data.translations[0].translatedText;
                } else {
                    // If the text is already in English, no need to translate
                    translatedText = postData.content.text;
                }
            }

            // Step 2: Moderate the translated text using ChatGPT moderation API
            const moderationResponse = await axios.post(CHATGPT_MODERATION_URL, {
                input: translatedText,
            }, {
                headers: {
                    Authorization: `Bearer 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`
                }
            });

            const moderationResult = moderationResponse.data.results[0];

            console.log("moderation response ", moderationResult)

            // Define the forbidden categories
            const forbiddenCategories = [
                'sexual/minors',
                'harassment/threatening',
                'hate/threatening',
                'self-harm/intent',
                'self-harm',
                'self-harm/instructions',
                // Add other forbidden categories if necessary
            ];

            // Determine content category based on moderation result
            let category;
            if (moderationResult.flagged) {
                // Check if any forbidden category is present
                const isForbiddenContent = forbiddenCategories.some(category => moderationResult.categories[category]);

                if (isForbiddenContent) {
                    alert("Illegal or Violating Content Detected. Unable to process post.");
                    return;
                } else {
                    category = "unrated"; // Harsh content (not illegal)
                }
            } else {
                category = "normal"; // Safe content
            }

            // Step 3: Analyze sentiment using the translated text
            const sentiment = new Sentiment();
            const analysis = sentiment.analyze(translatedText);
            const sentimentScoreCompare = analysis.comparative;
            const sentimentScore = analysis.score;

            let mediaUrl = null;

            // Step 4: Check if an image file is provided
            // may need to change this postData.content.media.size > 0 if not working
            if (postData.content.media.size > 0) {
                const mediaFile = postData.content.media;

                // Step 4a: Moderate the image using Google Cloud Vision API SafeSearch Detection
                // Read the image file and convert it to base64
                const readFileAsBase64 = (file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const base64String = reader.result.replace(/^data:.+;base64,/, '');
                            resolve(base64String);
                        };
                        reader.onerror = reject;
                        reader.readAsDataURL(file);
                    });
                };
                const base64Image = await readFileAsBase64(mediaFile);

                // Prepare the request to Google Cloud Vision API
                const visionAPIUrl = `${VISION_API_URL}?key=AIzaSyBczKTFqESInEK1OMnoRdkEXz1-AtYaGlk`;

                const visionRequestPayload = {
                    requests: [
                        {
                            image: {
                                content: base64Image,
                            },
                            features: [
                                {
                                    type: 'SAFE_SEARCH_DETECTION',
                                },
                            ],
                        },
                    ],
                };

                const visionResponse = await axios.post(visionAPIUrl, visionRequestPayload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const safeSearch = visionResponse.data.responses[0].safeSearchAnnotation;

                console.log('SafeSearch results: ', safeSearch);

                const unacceptableLikelihoods = ['LIKELY', 'VERY_LIKELY'];

                if (
                    unacceptableLikelihoods.includes(safeSearch.adult) ||
                    unacceptableLikelihoods.includes(safeSearch.violence) ||
                    unacceptableLikelihoods.includes(safeSearch.racy) ||
                    unacceptableLikelihoods.includes(safeSearch.medical) ||
                    unacceptableLikelihoods.includes(safeSearch.spoof)
                ) {
                    category = 'unrated';
                }

                // Step 4b: Upload the image to Firebase Storage
                const uniqueFileName = `${uuidv4()}_${mediaFile.name}_${Date.now()}`;
                const storageRef = ref(storage, `posts/${postData.user.user_uid}/${uniqueFileName}`);

                const uploadTask = uploadBytesResumable(storageRef, mediaFile);

                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log(`Upload is ${progress}% done`);
                        },
                        (error) => reject(error),
                        async () => {
                            mediaUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve();
                        }
                    );
                });
            }

            // Step 5: Build the post data according to your schema
            const post = {
                user: {
                    user_uid: postData.user.user_uid,
                    '@name': postData.user['@name'],
                    display_name: postData.user.display_name,
                    user_photo_url: postData.user.user_photo_url,
                },
                engagement: {
                    likes: 0,
                    comments: 0,
                    shares: 0,
                    views: 0,
                    reported: false,
                },
                content: {
                    text: postData.content.text,
                    searchable_text: translatedText, // Store the translated text for future search
                    media: mediaUrl,
                    hashtags: postData.content.hashtags,
                    mentions: postData.content.mentions,
                    country: postData.content.location,
                    language: detectedLanguage, // Store detected language
                },
                postType: 'personal',
                sentiment: sentimentScore,
                sentiment_compare: sentimentScoreCompare,
                created: serverTimestamp(),
                moderation_category: category, // Store content category (normal, unrated)
            };

            // Step 6: Save the post to Firestore
            const postCollectionRef = collection(firestore, 'posts');
            const response = await addDoc(postCollectionRef, post);

        } catch (error) {
            console.log(error)
            return rejectWithValue(error.message);
        }
    }
);

// Thunk to fetch posts from Firestore
export const fetchPosts = createAsyncThunk(
    'social/fetchPosts',
    async (_, { rejectWithValue }) => {
        try {
            const postCollectionRef = collection(firestore, 'posts');

            // Create a query that orders posts by timestamp in descending order
            const postsQuery = query(postCollectionRef, orderBy('created', 'desc'), limit(4));

            // Fetch the documents based on the query
            const snapshot = await getDocs(postsQuery);

            // Map over the documents to extract data
            const posts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            return posts;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const socialSlice = createSlice({
    name: 'social',
    initialState: {
        posts: [],
        loading: false,
        error: null,
    },
    reducers: {
        setPosts: (state, action) => {
            state.posts = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle adding a post
            .addCase(addPost.pending, (state) => {
                state.loading = true;
            })
            // .addCase(addPost.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.posts.unshift(action.payload);  // Add new post to the top of the feed
            // })
            .addCase(addPost.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Handle fetching posts
            .addCase(fetchPosts.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.loading = false;
                state.posts = action.payload;  // Set posts to state
            })
            .addCase(fetchPosts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
})

// Action creators are generated for each case reducer function
export const { setPosts } = socialSlice.actions

export default socialSlice.reducer