import React, { useState } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faApple, faFacebook } from "@fortawesome/free-brands-svg-icons";
import logo from '../assets/placeholder-image.png'
import { auth, googleAuthProvider, facebookAuthProvider, signInWithPopup, xAuthProvider, signOut } from '../firebase';
import { faX } from '@fortawesome/free-solid-svg-icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUser, signinUser } from '../redux/slices/userSlice';

//lib
import { extractSerializableUserData } from '../lib/userUtils'
import { useNavigate } from 'react-router-dom';

function LoginModal() {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleAuthProvider);

            const user = result.user;

            // Dispatch the signinUser thunk to fetch the user document from Firestore
            dispatch(signinUser(user.uid))
                .then((resultAction) => {
                    if (signinUser.fulfilled.match(resultAction)) {
                        console.log('Sign-in successful');
                        navigate('/home');
                        // User is successfully signed in and the document exists
                    } else if (signinUser.rejected.match(resultAction)) {
                        // If no document found or error occurs, sign the user out
                        console.log('Error:', resultAction.payload);
                        if (resultAction.payload === 'No account found. Signing out.') {
                            auth.signOut()
                                .then(() => {
                                    dispatch(setUser(null))
                                    console.log('User signed out successfully');
                                })
                                .catch((error) => {
                                    console.error('Error during sign-out:', error);
                                });
                            alert('You do not have an account! Please "Join Now" instead');
                        }
                    }
                });
        } catch (error) {
            console.log('Error during Google sign-in:', error.message);
        }

        // signInWithPopup(auth, googleAuthProvider)
        //     .then((payload) => {
        //         console.log(payload.user)
        //         dispatch(signinUser(payload.user));
        //     })
        //     .catch((error) => console.log(error.message));
    }

    const signInWithFacebook = async () => {
        try {
            const result = await signInWithPopup(auth, facebookAuthProvider);

            const user = result.user;

            // Dispatch the signinUser thunk to fetch the user document from Firestore
            dispatch(signinUser(user.uid))
                .then((resultAction) => {
                    if (signinUser.fulfilled.match(resultAction)) {
                        console.log('Sign-in successful');
                        navigate('/home');
                        // User is successfully signed in and the document exists
                    } else if (signinUser.rejected.match(resultAction)) {
                        // If no document found or error occurs, sign the user out
                        console.log('Error:', resultAction.payload);
                        if (resultAction.payload === 'No account found. Signing out.') {
                            auth.signOut()
                                .then(() => {
                                    dispatch(setUser(null))
                                    console.log('User signed out successfully');
                                })
                                .catch((error) => {
                                    console.error('Error during sign-out:', error);
                                });
                            alert('You do not have an account! Please "Join Now" instead');
                        }
                    }
                });
        } catch (error) {
            console.log('Error during Google sign-in:', error.message);
        }
    }

    const signInWithX = async () => {
        try {
            const result = await signInWithPopup(auth, xAuthProvider);

            const user = result.user;

            // Dispatch the signinUser thunk to fetch the user document from Firestore
            dispatch(signinUser(user.uid))
                .then((resultAction) => {
                    if (signinUser.fulfilled.match(resultAction)) {
                        console.log('Sign-in successful');
                        navigate('/home');
                        // User is successfully signed in and the document exists
                    } else if (signinUser.rejected.match(resultAction)) {
                        // If no document found or error occurs, sign the user out
                        console.log('Error:', resultAction.payload);
                        if (resultAction.payload === 'No account found. Signing out.') {
                            auth.signOut()
                                .then(() => {
                                    dispatch(setUser(null))
                                    console.log('User signed out successfully');
                                })
                                .catch((error) => {
                                    console.error('Error during sign-out:', error);
                                });
                            alert('You do not have an account! Please "Join Now" instead');
                        }
                    }
                });
        } catch (error) {
            console.log('Error during Google sign-in:', error.message);
        }
    }

    // const signInWithApple = () => {
    //     auth.signInWithPopup(appleAuthProvider)
    //         .then((payload) => {
    //             dispatch(setUserOrCreateAndSet(payload))
    //         })
    //         .catch((error) => alert(error.message));
    // }

    return (
        <>
            <Button outline className='loginModal__button shadow-none py-1 my-1 make-round'
                onClick={() => setIsLoginModalOpen(isLoginModalOpen ? false : true)}
            >Sign in</Button>
            <Modal
                className='createWurker__modal'
                size="sm"
                isOpen={isLoginModalOpen}
                toggle={() => setIsLoginModalOpen(isLoginModalOpen ? false : true)}
            >
                <ModalHeader toggle={() => setIsLoginModalOpen(false)}>
                    <p className='text-secondary m-0'><strong>Sign in</strong></p>
                </ModalHeader>
                <ModalBody className='loginModal__body d-flex flex-column mx-auto pt-4 text-center'>
                    <img src={logo} className="loginModal__wurkloLogo mx-auto round" alt="bluith logo" />
                    <p className='loginModal__logoText notranslate'>bluith</p>
                    <Button
                        outline
                        color='danger'
                        className='googleSignin__button round shadow-none p-2 px-4 mt-0'
                        onClick={signInWithGoogle}
                    >
                        <FontAwesomeIcon icon={faGoogle} className="fs-5 me-2" />
                        Sign in with Google
                    </Button>
                    <Button
                        outline
                        color='secondary'
                        className='appleSignin__button round shadow-none p-2 px-4 mt-3'
                    // onClick={signInWithApple}
                    >
                        <FontAwesomeIcon icon={faApple} className="fs-5 me-2" />
                        Sign in with Apple
                    </Button>
                    <Button
                        outline
                        color='primary'
                        className='microsoftSignin__button round shadow-none p-2 px-4 mt-3'
                        onClick={signInWithFacebook}
                    >
                        <FontAwesomeIcon icon={faFacebook} className="fs-5 me-2" />
                        Sign in with Facebook
                    </Button>
                    <Button
                        outline
                        color='danger'
                        className='yahooSignin__button round shadow-none p-2 px-4 mt-3'
                        onClick={signInWithX}
                    >
                        <FontAwesomeIcon icon={faX} className="fs-5 me-2" />
                        Sign in with X
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default LoginModal