import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Input, Row } from 'reactstrap';
import PostCard from '../../components/PostCard';
import PostCreator from '../../components/PostCreator';
import { firestore } from '../../firebase';
import { fetchPosts, setPosts } from '../../redux/slices/socialSlice';

function SocialPage() {
    const dispatch = useDispatch();
    const { user, userData, userProfileData } = useSelector((state) => state.user);
    const { posts } = useSelector((state) => state.social);

    useEffect(() => {
        // Set up real-time listener for Firestore posts collection, ordered by creation time and limited to 10 posts
        const q = query(
            collection(firestore, "posts"),
            where("moderation_category", "==", "normal"),
            orderBy("created", "desc"),  // Assuming "created" is a timestamp field
            limit(8)
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedPosts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            dispatch(setPosts(updatedPosts));  // Dispatch action to update posts in Redux
        });

        // Clean up the listener when component unmounts
        return () => unsubscribe();
    }, [dispatch]);

    return (
        <Container fluid className="d-flex justify-content-center g-0">
            <div>
                <div className='d-flex text-center'>
                    <div className='bg-secondary w-50 p-1'>Clean</div>
                    <div className='bg-white w-50 p-1'>Groups</div>
                    <div className='bg-white w-50 p-1'>Underground</div>
                </div>
                <PostCreator />
                <div>
                    {posts?.map((post, index) => (
                        <PostCard key={index} post={post} />
                    ))}
                </div>
            </div>

        </Container>
    )
}

export default SocialPage