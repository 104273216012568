import React, { useState } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faApple, faMicrosoft, faYahoo, faGithub, faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";
import logo from '../assets/placeholder-image.png'
import { auth, googleAuthProvider, facebookAuthProvider, signInWithPopup, xAuthProvider, signOut } from '../firebase';
import { faX } from '@fortawesome/free-solid-svg-icons';

// redux shit
import { useDispatch, useSelector } from 'react-redux';
import { setUser, createUser } from '../redux/slices/userSlice';

// lib
import { extractSerializableUserData } from '../lib/userUtils'
import { useNavigate } from 'react-router-dom';

function JoinNowModal() {
    const [isJoinNowOpen, setIsJoinNowOpen] = useState(false);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const joinNowWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const user = result.user;
            // Dispatch the createUser thunk, which checks if the user exists
            dispatch(createUser({ user }))
                .then((resultAction) => {
                    if (createUser.fulfilled.match(resultAction)) {
                        const userData = resultAction.payload.userData;
                        console.log('Signup successful');
                        navigate('/home');
                        // Redirect user to their dashboard or app
                    } else if (createUser.rejected.match(resultAction)) {
                        // If rejected, show an appropriate message
                        if (resultAction.payload === 'User already exists. Please log in instead.') {
                            signOut(auth)
                                .then(() => {
                                    dispatch(setUser(null))
                                    console.log('User signed out successfully');
                                })
                                .catch((error) => {
                                    console.error('Error during sign-out:', error);
                                });
                            alert('You already have an account! Please "Sign in" instead');
                        } else {
                            console.error('Error during signup:', resultAction.payload);
                        }
                    }
                });
        } catch (error) {
            console.error('Error during Google sign-up', error);
        }
    }

    const joinNowWithFacebook = async () => {
        try {
            const result = await signInWithPopup(auth, facebookAuthProvider);
            const user = result.user;
            // Dispatch the createUser thunk, which checks if the user exists
            dispatch(createUser({ user }))
                .then((resultAction) => {
                    if (createUser.fulfilled.match(resultAction)) {
                        const userData = resultAction.payload.userData;
                        console.log('Signup successful');
                        navigate('/home');
                        // Redirect user to their dashboard or app
                    } else if (createUser.rejected.match(resultAction)) {
                        // If rejected, show an appropriate message
                        if (resultAction.payload === 'User already exists. Please log in instead.') {

                            signOut(auth)
                                .then(() => {
                                    dispatch(setUser(null))
                                    console.log('User signed out successfully');
                                })
                                .catch((error) => {
                                    console.error('Error during sign-out:', error);
                                });
                            alert('You already have an account! Please "Sign in" instead');
                        } else {
                            console.error('Error during signup:', resultAction.payload);
                        }
                    }
                });
        } catch (error) {
            console.error('Error during Google sign-up', error);
        }
    }

    const joinNowWithX = async () => {
        try {
            const result = await signInWithPopup(auth, xAuthProvider);
            const user = result.user;
            // Dispatch the createUser thunk, which checks if the user exists
            dispatch(createUser({ user }))
                .then((resultAction) => {
                    if (createUser.fulfilled.match(resultAction)) {
                        const userData = resultAction.payload.userData;
                        console.log('Signup successful');
                        navigate('/home');
                        // Redirect user to their dashboard or app
                    } else if (createUser.rejected.match(resultAction)) {
                        // If rejected, show an appropriate message
                        if (resultAction.payload === 'User already exists. Please log in instead.') {

                            signOut(auth)
                                .then(() => {
                                    dispatch(setUser(null))
                                    console.log('User signed out successfully');
                                })
                                .catch((error) => {
                                    console.error('Error during sign-out:', error);
                                });
                            alert('You already have an account! Please "Sign in" instead');
                        } else {
                            console.error('Error during signup:', resultAction.payload);
                        }
                    }
                });
        } catch (error) {
            console.error('Error during Google sign-up', error);
        }
    }

    // const joinNowWithApple = () => {
    //     auth.signInWithPopup(appleAuthProvider)
    //         .then((payload) => {
    //             dispatch(createUser(payload));
    //         })
    //         .catch((error) => alert(error.message));
    // }

    return (
        <>
            <Button color='secondary' outline className='joinNow__button me-3 my-1 py-1 border-0 shadow-none round'
                onClick={() => setIsJoinNowOpen(isJoinNowOpen ? false : true)}
            >Join Now</Button>
            <Modal
                className='createWurker__modal'
                size="sm"
                isOpen={isJoinNowOpen}
                toggle={() => setIsJoinNowOpen(isJoinNowOpen ? false : true)}
            >
                <ModalHeader toggle={() => setIsJoinNowOpen(false)}>
                    <p className='text-secondary m-0'><strong>Join Now</strong></p>
                </ModalHeader>
                <ModalBody className='d-flex flex-column mx-auto pt-4 text-center'>
                    <img src={logo} className="loginModal__wurkloLogo mx-auto round" alt="bluith logo" />
                    <p className='loginModal__logoText notranslate'>bluith</p>
                    <div className='align-self-center text-start mb-3'>
                        <p className=''>✅ Terms & Conditions</p>
                        <p className=''>✅ Authorize browser data pull</p>
                        <p className=''>✅ 18 or older</p>
                        <p className=''>🔲 14-17 w/parents permission</p>
                    </div>
                    <Button
                        outline
                        color='danger'
                        className='googleSignin__button round shadow-none p-2 px-4 mt-0'
                        onClick={joinNowWithGoogle}
                    >
                        <FontAwesomeIcon icon={faGoogle} className="fs-5 me-2" />
                        Join Now with Google
                    </Button>
                    <Button
                        outline
                        color='secondary'
                        className='appleSignin__button round shadow-none p-2 px-4 mt-3'
                    // onClick={joinNowWithApple}
                    >
                        <FontAwesomeIcon icon={faApple} className="fs-5 me-2" />
                        Join Now with Apple
                    </Button>
                    <Button
                        outline
                        color='primary'
                        className='microsoftSignin__button round shadow-none p-2 px-4 mt-3'
                        onClick={joinNowWithFacebook}
                    >
                        <FontAwesomeIcon icon={faFacebook} className="fs-5 me-2" />
                        Join Now with Facebook
                    </Button>
                    <Button
                        outline
                        className='yahooSignin__button round shadow-none p-2 px-4 mt-3'
                        onClick={joinNowWithX}
                    >
                        <FontAwesomeIcon icon={faX} className="fs-5 me-2" />
                        Join Now with X
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default JoinNowModal