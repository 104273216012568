// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCgDFiWi8sSvpb244HveJ-TwgGHjxNBBAQ",
    authDomain: "bluith-5dd6a.firebaseapp.com",
    projectId: "bluith-5dd6a",
    storageBucket: "bluith-5dd6a.appspot.com",
    messagingSenderId: "11865279228",
    appId: "1:11865279228:web:5fa87002900f44d1b62579",
    measurementId: "G-8G454TPRC0"
  };

const app = initializeApp(firebaseConfig);
// Initialize Firestore
const firestore = getFirestore(app);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const xAuthProvider = new TwitterAuthProvider();
// Initialize Firebase Storage
const storage = getStorage(app);

export { auth, firestore, storage, googleAuthProvider, facebookAuthProvider, xAuthProvider, signInWithPopup, signOut };